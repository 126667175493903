.container {
  margin: 0 auto;
  padding: 1rem;
  max-width: 75%;
  
  @include sm-up {
    max-width: 40rem;
    padding: 2rem;
  }
}

.main--container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}