@import "utilities/variables";
@import "utilities/mixins";

@import "base/reset";
@import "base/typograhpy";

@import "layout/navigation";
@import "layout/grid";

@import "components/blockquote";

@import "pages/home";

body {
  background: $bgColor;
  color: $textColor;
  font-family: $bodyFont;
}