blockquote {
  padding: 0 1rem;
  color: rgb(40, 40, 40);
  border-left: .25rem solid $textColor;
  margin: 0 0 1rem;

  p {
    color: $textColor;
    margin-bottom: 0;
  }
}