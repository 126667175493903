// grid breakpoint
@mixin sm-up {
  @media screen and (min-width: $sm) {
    @content;
  }
}

@mixin md-up {
  @media screen and (min-width: $md) {
    @content;
  }
}

@mixin lg-up {
  @media screen and (min-width: $lg) {
    @content;
  }
}

@mixin xl-up {
  @media screen and (min-width: $xl) {
    @content;
  }
}