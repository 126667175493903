@font-face {
  font-family: 'Anonymous Pro Regular';
  src: url('/assets/fonts/AnonymousPro-Regular.woff2') format('woff2'),
    url('/assets/fonts/AnonymousPro-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Anonymous Pro Italic';
  src: url('/assets/fonts/AnonymousPro-Italic.woff2') format('woff2'),
    url('/assets/fonts/AnonymousPro-Italic.woff') format('woff');
}

h1 {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1rem;
  
  @include sm-up {
    font-size: 3rem;
  }
}

h2 {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  
  @include sm-up {
    font-size: 2.5rem;
  }
}

h3 {
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  
  @include sm-up {
    font-size: 2rem;
  }
}

h4 {
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 1rem;
  
  @include sm-up {
    font-size: 1.75rem;
  }
}

p, li {
  line-height: 1.2;
  margin-bottom: 1rem;

  @include md-up {
    font-size: 1.25rem;
  }
}

a {
  color: $accentColor;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  
  &:hover {
    text-decoration: underline;
  }
}

.bold {
  font-weight: 700;
}