// grid breakpoints
$sm: 768px;
$md: 1024px;
$lg: 1280px;
$xl: 1600px;

$bgColor: #010101;
$textColor: #fefefe;
$textSecondary: #afafaf;
$accentColor: #00ee00;

$bodyFont: 'Anonymous Pro Regular',-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$bodyFontItalic: 'Anonymous Pro Italic',-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";